* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 2;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

header {
  text-shadow: 4px 4px 15px rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

header h3 {
  margin-top: 10px;
  font-weight: 450;
  color: white;
}

header h1 {
  color: white;
  font-size: 2.8rem;
}
@media only screen and (min-width: 480px) {
  header h1 {
    font-size: 3rem;
  }
}
@media only screen and (min-width: 992px) {
  header h1 {
    font-size: 5.2rem;
  }
}
@media only screen and (min-width: 1280px) {
  header h1 {
    font-size: 4rem;
  }
}

header p {
  color: white;
  font-style: italic;
  font-size: 0.9rem;
}
@media only screen and (min-width: 480px) {
  header p {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 992px) {
  header p {
    font-size: 1.6rem;
  }
}
@media only screen and (min-width: 1280px) {
  header p {
    font-size: 1.2rem;
  }
}

.bg-image {
  background-image: url('assets/hero-image.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  z-index: -1 !important;
}

.hero {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  height: 90vh;

  position: relative;
}
@media only screen and (min-width: 480px) {
  .hero {
    height: 100vh;
  }
}

.shadow {
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.schedule {
  padding: 10px;
  display: flex;
  border-bottom: 2px solid white;
  border-top: 2px solid white;
  gap: 10px;
}

.schedule > div {
  display: flex;
  flex-direction: column;
}

.content .address {
  text-shadow: 4px 4px 15px rgb(0, 0, 0);

  color: white;
  text-align: right;
  font-size: 0.8rem;
  font-style: italic;

  position: absolute;
  bottom: 15px;
  right: 15px;
}
@media only screen and (min-width: 768px) {
  .address {
    font-size: 0.9rem;
  }
}
@media only screen and (min-width: 992px) {
  .address {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 1280px) {
  .address {
    font-size: 1rem;
  }
}

.lang {
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.lang > div {
  border-radius: 50%;

  width: 24px;
  height: 24px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (min-width: 480px) 
{
  .lang > div {
    width: 26px;
    height: 26px;
  }
}

.lang > div:hover {
  cursor: pointer;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 2;

  padding: 40px 0;
}
@media only screen and (min-width: 1280px) {
  .content {
    justify-content: space-between;
  }
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.contact * {
  color: white;
}

.contact > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.contact p {
  text-shadow: 4px 4px 15px rgb(0, 0, 0);
  
  display: none;
  visibility: hidden;
}
@media only screen and (min-width: 768px) {
  .contact p {
    display: block;
    visibility: visible;
    font-size: 0.9rem;
  }
}
@media only screen and (min-width: 992px) {
  .contact p {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1280px) {
  .contact p {
    font-size: 1rem;
  }
}

.contact .icon {
  padding: 10px;
  z-index: 2;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.45);
  transition: 0.5s;

  width: 20px;
  height: 20px;
}
@media only screen and (min-width: 480px) {
  .contact .icon {
    width: 24px;
    height: 24px;
  }
}
@media only screen and (min-width: 768px) {
  .contact .icon {
    width: 26px;
    height: 26px;
  }
}
@media only screen and (min-width: 992px) {
  .contact .icon {
    width: 40px;
    height: 40px;
    padding: 16px;
  }
}
@media only screen and (min-width: 1280px) {
  .contact .icon {
    width: 30px;
    height: 30px;
    padding: 14px;
  }
}

.contact .icon:hover {
  cursor: pointer;
  background-color: rgba(70, 70, 70, 0.7);
}

.comments {
  padding: 40px 10px;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  background-color: rgba(155, 155, 155, 0.1);
  backdrop-filter: blur(5px);
}

.comments > h2,
.gallery > h2 {
  color: white;
  font-size: 2rem;
  font-weight: 500;

  text-shadow: 4px 4px 15px rgb(0, 0, 0);
}

.cards {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.card {
  background-color: rgba(255, 255, 255, 0.75);
  padding: 14px;
  border-radius: 6px;
  box-shadow: 5px 5px 15px lightslategrey;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  width: 300px;
}

.card p {
  font-size: 0.8rem;
}
@media only screen and (min-width: 768px) {
  .card p {
    font-size: 1rem;
  }
}

.card h3 {
  font-size: 1rem;
}
@media only screen and (min-width: 768px) {
  .card h3 {
    font-size: 1.2rem;
  }
}

.stars {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;  
}

.gallery {
  padding: 40px 0;

  align-items: center;
  background-color: rgba(155, 155, 155, 0.1);
  backdrop-filter: blur(5px);
}

.pictures {
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  gap: 20px;
}

.pictures img {
  box-shadow: 3px 3px 20px black;
  width: 90%;
  height: auto;

  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}
@media only screen and (min-width: 480px) {
  .pictures img {
    width: 340px;
    height: 340px;
  }
}

.map {
  align-items: center;

  padding: 40px 0;

  background-color: rgba(155, 155, 155, 0.1);
  backdrop-filter: blur(5px);
}

.map iframe {
  margin-top: 30px;

  width: 100%;
  height: 400px;
}
@media only screen and (min-width: 480px) {
  .map iframe {
    width: 90%;
  }
}
@media only screen and (min-width: 992px) {
  .map iframe {
    width: 990px;
    height: 700px;
  }
}
@media only screen and (min-width: 1280px) {
  .map iframe {
    width: 1200px;
    height: 760px;
  }
}

footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid white;
  padding: 10px 20px;
}

footer * {
  color: whitesmoke;
}
